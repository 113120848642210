<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {authHeader} from '../../../helpers/fakebackend/auth-header';

import {required} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Banner",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,

  },
  data() {
    return {
      title: "Add Banner",
      items: [
        {
          text: "Banner",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


      status: '',
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      restaurants: [],
      encodeImage: "",
      imageData: "",
      bannerTitle: "",
      shortDesc: "",
      bannerType: "1",
      bannerOptionType: "1",
      cuisineList: [],
      bannerData: [],
      cuisineType: "",
      discount: "",
      displayName: "",
      enum: "",
      bannerID: 0,
      foodCourtList: [],
      foodCourtResList: [],
      foodCourtID: "",
      restBranchID: "",
      cafeteriaID: "",
      cafeteriaList: [],
    };
  },

  validations: {
    bannerTitle: {
      required,
    },
    shortDesc: {
      required,
    },
  },
  methods: {

    submitForm() {

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append('bannerTitle', this.bannerTitle);
        formData.append('shortDesc', this.shortDesc);
        formData.append('bannerType', this.bannerType);
        formData.append('bannerOptionType', this.bannerOptionType);
        formData.append('discount', this.discount);
        formData.append('cuisineType', this.cuisineType.cuisineID);
        formData.append('enum', sessionStorage.getItem('enum'));
        formData.append('document', this.encodeImage);
        formData.append('createdBy', this.$storageData.profile.pid);
        formData.append('loginTypeID', this.$storageData.login_type);
        formData.append('foodCourtID', this.foodCourtID.foodCourtID);
        if (sessionStorage.getItem('enum') == 'cafeteria') {
          formData.append('restID', this.cafeteriaID.restBranchID);
        } else {
          formData.append('restID', this.restBranchID.restBranchID);
        }

        this.axios.post(this.$loggedRole + "/addBannerForm", formData, {
          headers: authHeader()
        })
            .then((result) => {
              this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;
              setTimeout(() => {
                this.$router.push({name: 'bannerDetails'});
              }, 2000);

              // console.warn(result);

            })
            .catch(function (error) {
              this.loading = false;
              console.error(error.response);
              alert("Something went Wrong");
            });
      }
    },
    getRestaurantList() {
      this.axios.post(this.$loggedRole + "/getRestaurantList",
          {
            'restID': this.$storageData.profile.restID,
            'restBranchID': this.$storageData.profile.restBranchID,
            'empTypeID': this.$storageData.profile.empTypeID
          })
          .then((result) => {
            this.restaurants = result.data.data;

          });
    },

    getFoodCourtList() {
      this.axios.post(this.$loggedRole + "/viewAllFoodCourt")
          .then((result) => {
            this.foodCourtList = result.data.data;
          });
    },

    previewImage: function (event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.encodeImage = input.files[0];


      }

    },
    getCuisineList() {
      this.axios.get(this.$loggedRole + "/getCuisineList")
          .then((result) => {
            this.cuisineList = result.data.data;

          });
    },

    viewFoodCourtRestaurant() {
      this.axios.post(this.$loggedRole + "/viewFoodCourtRestaurant", {'foodCourtID': this.foodCourtID.foodCourtID}
      )
          .then((result) => {
            this.foodCourtResList = result.data.data;

          });
    },

    getBannerByID(bannerID) {
      this.axios.post(this.$loggedRole + "/getBannerByID", {bannerID: bannerID})
          .then((result) => {
            this.bannerData = result.data.data;
            this.bannerID = this.bannerData.bannerID;
            this.bannerTitle = this.bannerData.title;
            this.shortDesc = this.bannerData.description;
            this.bannerType = this.bannerData.actionType;
            this.discount = this.bannerData.discount;
            this.bannerType = this.bannerData.actionType;
            this.imageData = this.bannerData.bannerImage
            this.cuisineType = this.bannerData.cuisineID;

          });
    },

    updateForm() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append('bannerID', this.bannerID);
        formData.append('bannerTitle', this.bannerTitle);
        formData.append('shortDesc', this.shortDesc);
        formData.append('bannerType', this.bannerType);
        formData.append('bannerOptionType', this.bannerOptionType);
        formData.append('discount', this.discount);
        formData.append('cuisineType', this.cuisineType.cuisineID);
        formData.append('enum', sessionStorage.getItem('enum'));
        if (sessionStorage.getItem('enum') == 'cafeteria') {
          formData.append('restID', this.cafeteriaID.restBranchID);
        }
        formData.append('document', this.encodeImage);
        formData.append('createdBy', this.$storageData.profile.pid);
        formData.append('loginTypeID', this.$storageData.login_type);


        this.axios.post(this.$loggedRole + "/updateBannerForm", formData, {
          headers: authHeader()
        })
            .then((result) => {
              this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                this.$router.push({name: 'bannerDetails'});
              }, 2000);

              // console.warn(result);

            })
            .catch(function (error) {
              this.loading = false;
              console.error(error.response);
              alert("Something went Wrong");
            });
      }
    },
    getCafeteriaList() {
      this.axios.get(this.$loggedRole + "/viewCafeRestaurant")
          .then((result) => {
            this.cafeteriaList = result.data.data;
            this.cafeteriaID = (this.cafeteriaList) ? this.cafeteriaList[0] : "";
            this.getCafeteriaBanners();
          });
    },


  },
  mounted() {
    this.getCuisineList();
    this.getFoodCourtList();
    this.getCafeteriaList();
    if (this.$route.params.enum) {
      // alert(this.id);
      sessionStorage.setItem('enum', this.$route.params.enum);// Save to sessionStorage
      sessionStorage.setItem('displayName', this.$route.params.displayName);
      sessionStorage.setItem('bannerID', this.$route.params.bannerID);

    }
    this.displayName = sessionStorage.getItem('displayName');
    this.enum = sessionStorage.getItem('enum');
    if (sessionStorage.getItem('bannerID') > 0) {
      this.getBannerByID(sessionStorage.getItem('bannerID'));
    }

  },


  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Banner Added Successfully!
      </b-alert>
    </div>


    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <h5 class="mb-3"> Banner for {{ displayName }} </h5>
          <div class="mb-3 col-md-6" v-if="this.enum =='food_court'">
            <label>Select Food Court *</label>
            <multiselect v-model="foodCourtID" :options="foodCourtList" track-by="foodCourtID" label="title"
                         @input="viewFoodCourtRestaurant();" :class="{
                              'is-invalid': submitted && $v.foodCourtID.$error,
                            }"></multiselect>
            <div
                v-if="submitted && $v.foodCourtID.$error"
                class="invalid-feedback">
                            <span v-if="!$v.foodCourtID.required"
                            >This value is required.</span>
            </div>
          </div>

          <!-- div for cafe banner list -->
          <div class="mb-3 col-md-6" v-if="this.enum =='cafeteria'">
            <label>Select Cafeteria *</label>
            <multiselect v-model="cafeteriaID" :options="cafeteriaList" track-by="restBranchID"
                         label="restaurantName"></multiselect>
            <div
                v-if="submitted && $v.cafeteriaID.$error"
                class="invalid-feedback">
                            <span v-if="!$v.cafeteriaID.required"
                            >This value is required.</span>
            </div>
          </div>
          <!-- div for cafe banner list -->
          <div class="row">


            <div class="mb-3 col-md-6">
              <label class="form-label" for="formrow-restName-input">Select Banner Image (Square):</label>
              <div class="file-upload-form">
                Choose File
                <input type="file" class="uploadInput" @change="previewImage" accept="image/*">
              </div>

            </div>
            <div class="col-md-6">
              <img class="preBuyImageTempalet1" :src="(imageData)? imageData : $placeholder">
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mt-3">
              <label class="form-label" for="formrow-bannerTitle-input">Title: *</label>
              <input type="text" :maxlength="30" class="form-control" id="formrow-bannerTitle-input" name="title"
                     v-model="bannerTitle" :class="{
                        'is-invalid': submitted && $v.bannerTitle.$error,
                      }">

              <p style="right: 9px;" v-if="bannerTitle" class="badge position-absolute" :class="{ 'bg-success': bannerTitle.length !== 30,
                            'bg-danger': bannerTitle.length === 30 }">

                {{ bannerTitle.length }} / 30
              </p>

              <div
                  v-if="submitted && $v.bannerTitle.$error"
                  class="invalid-feedback"
              >
                              <span v-if="!$v.bannerTitle.required"
                              >This value is required.</span
                              >
              </div>
            </div>

            <div class="col-md-6 mt-3">
              <label class="form-label" for="formrow-des-input"> Short Description: *</label>
              <textarea name="textarea" :maxlength="50" v-model="shortDesc" rows="3" class="form-control"
                        :class="{
                        'is-invalid': submitted && $v.shortDesc.$error,
                      }"></textarea>
              <p style="right: 19px;" v-if="shortDesc" class="badge position-absolute" :class="{ 'bg-success': shortDesc.length !== 50,
                            'bg-danger': shortDesc.length === 50 }">

                {{ shortDesc.length }} / 50
              </p>

              <div
                  v-if="submitted && $v.shortDesc.$error"
                  class="invalid-feedback"
              >
                          <span v-if="!$v.shortDesc.required"
                          >This value is required.</span
                          >
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6">
              <label class="form-label"> Select Banner Type :</label>

              <b-form-radio
                  v-model="bannerType"
                  class="custom-radio mb-3"
                  value="3"
                  plain
              >Banner with no action
              </b-form-radio
              >
              <b-form-radio

                  v-model="bannerType"
                  class="mb-3"
                  value="1"
                  plain
              >Show Restaurant List
              </b-form-radio
              >
              <div style="margin-left: 55px;" v-if="bannerType==1">
                <div class="row">
                  <b-form-radio

                      v-model="bannerOptionType"
                      class=" col-md-6"
                      value="1"
                      plain
                  >Restaurant with discount greater than
                  </b-form-radio
                  >

                  <div class="col-md-4" v-if="bannerOptionType==1">

                    <div class="input-group">
                      <input type="number" class="form-control" id="formrow-validFor-input" :min="0"
                             v-model="discount">
                      <div class="input-group-text">%</div>
                    </div>


                  </div>

                </div>
                <div class="row">
                  <b-form-radio

                      v-model="bannerOptionType"
                      class="col-md-6 mt-2"
                      value="2"
                      plain
                  >Restaurant with speciality
                  </b-form-radio
                  >

                  <div class="col-md-6" v-if="bannerOptionType==2">

                    <multiselect v-model="cuisineType" :options="cuisineList" track-by="cuisineID" label="cuisineName"
                                 :show-labels="false"></multiselect>


                  </div>
                </div>

              </div>
              <!-- v-if="this.$storageData.login_type==2" -->
              <b-form-radio
                  v-model="bannerType"
                  v-if="this.enum=='food_court'"
                  class="mb-3"
                  value="2"
                  plain
              >Open Restaurant Details
              </b-form-radio
              >
              <div class="col-md-6" v-if="bannerType==2">
                <multiselect v-model="restBranchID" :options="foodCourtResList" track-by="restBranchID"
                             label="restaurantName" :show-labels="false"></multiselect>
              </div>

            </div>

          </div>
          <div class=" row" style="float:right;">
            <b-spinner v-if="loading"
                       class="m-2 col-3"
                       variant="primary"
                       role="status"
            ></b-spinner>
            <button type="button" @click="bannerID>0 ? updateForm() : submitForm()"
                    class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit
            </button>
          </div>
        </div>

      </div>
    </div>


  </Layout>
</template>
<style>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}

.preBuyImageTempalet1 {
  height: 200px;
  width: 200px;
  -o-object-fit: contain;
  object-fit: contain;
}
</style>


